import { render, staticRenderFns } from "./Asynchronous.vue?vue&type=template&id=0db0d422&scoped=true&"
import script from "./Asynchronous.vue?vue&type=script&lang=js&"
export * from "./Asynchronous.vue?vue&type=script&lang=js&"
import style0 from "./Asynchronous.vue?vue&type=style&index=0&id=0db0d422&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0db0d422",
  null
  
)

export default component.exports