<template>
  <div class="asynchronous">
    <div class="async-title">任务列表</div>
    <div class="async-content">
      <div class="flex-end search-form">
        <el-form ref="form" :inline="true" size="small" :model="form" @submit.native.prevent>
          <el-form-item>
<!--            <el-button type="primary" @click="updateList">刷新</el-button>-->
          </el-form-item>
          <el-form-item>
            <el-input v-model="form.search" @input="clearInput" @keyup.enter.native="goSearch" placeholder="请输入任务名称">
            </el-input>
            <i @click="goSearch" class="el-icon-search search-btn"></i>
          </el-form-item>
        </el-form>
      </div>
      <el-table :data="tableData" style="width: 80%" v-loading="loading">
        <el-table-column prop="task_name" label="任务名称" width="370" show-overflow-tooltip>
          <template slot-scope="scope">
            <div class="task-name">{{ scope.row.task_name }}</div>
            <el-progress :percentage="percentStatus(scope.row)" :stroke-width="3" :show-text=false
                         :color="customColorMethod(scope.row)"></el-progress>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="状态" width="95">
          <template slot-scope="scope">
            <div class="fail-status" v-if="scope.row.status==1">排队中</div>
            <div class="ing-status" v-else-if="scope.row.status==2">进行中</div>
            <div class="pass-status" v-else-if="scope.row.status==10">已过期</div>
            <div class="success-status" v-else>已完成</div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="170">
          <template slot-scope="scope">
            <div>{{ scope.row.create_time }}</div>
          </template>
        </el-table-column>
        <el-table-column prop="create_time" label="创建人">
          <template slot-scope="scope">
            <div>
              <wxComponent v-if="allIsWx3=='true'" type="userName" :openid="scope.row.create_userid"/>
              <span v-else>{{scope.row.create_userid}}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                :disabled="scope.row.status==10"
                size="mini"
                class="tab-btn"
                type="text"
                v-show="scope.row.type===1"
                @click="checkDownload(scope.row)">下载列表
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex-end" style="width: 80%;margin:0 auto;" v-show="tableData.length">
        <el-pagination
            background
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
            :total="total">
        </el-pagination>
      </div>
    </div>
    <el-dialog title="下载列表" :visible.sync="dialogTask" @close="closeDialog">
      <el-table :data="gridData" style="width: 100%">
        <el-table-column property="job_name" label="模板名称"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <a v-if="isDownload" target="tempiframe" :href="scope.row.job_url" class="fz12 download-xlx">下载</a>
            <span class="fz12" v-else>生成中...</span>
            <iframe name="tempiframe" style="display: none">
            </iframe>
          </template>
        </el-table-column>
      </el-table>
      <div class="flex-end">
        <el-button
            size="mini"
            @click="dialogTask=false">取消
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {getTaskList, getTaskDetail, getExportId,downLoadZj,getTaskForZJ} from '@/api/workbench'
import wxComponent from "@/components/wx";
export default {
  name: "Asynchronous",
  data() {
    return {
      allIsWx3:true,
      isDownload:false,
      form: {
        search: '',
      },
      dialogTask: false,
      total: 0,
      tableData: [
        // {create_time: "2021-01-20 16:10:56",
        //   create_userid: "13730651954",
        //   dep_ids: "1",
        //   error_job_num: 0,
        //   finish_time: "2021-01-20 16:11:41",
        //   id: 9,
        //   job_num: 1,
        //   start_time: "2021-01-20 16:10:57",
        //   status: 2,
        //   success_job_num: 0,
        //   task_name: "导出人员关联关系模板任务2021-01-20 16:10",
        //   type: 1}
      ],
      detailTimer:'',
      taskListTimer:'',
      loading: false,
      gridData: [],
      jobNameList:[],
    }
  },
  components: {
     wxComponent
  },
  computed: {
    percentStatus() {
      return function (row) {
        const {job_num, success_job_num} = row
        const per=((success_job_num / job_num) * 100)>100 ? 100 : (success_job_num / job_num) * 100
        return job_num === 0 ? 0 : per
      }
    },
    customColorMethod() {
      return function (row) {
        const {job_num, success_job_num} = row
        let percentage =job_num === 0 || undefined || '' ? 0 : (success_job_num / job_num) * 100
        percentage > 100 ? percentage=100 : ''
        if (percentage < 30) {
          return '#e6a23c';
        } else if (percentage < 99) {
          return '#0082ef';
        } else {
          return '#67c23a';
        }
      }
    },
  },
  created() {
    this.allIsWx3=sessionStorage.getItem('is_wx3');
    if(process.env.NODE_ENV === 'production'){
      this.getDataList(1, 10)
    }
  },
  mounted() {
    if(this.allIsWx3=='true'){
      WWOpenData?.bindAll?.(document.querySelectorAll('ww-open-data'));
    }
  },
  beforeDestroy() {
    clearInterval(this.detailTimer)
    clearInterval(this.taskListTimer)
  },
  destroyed() {
    clearInterval(this.detailTimer)
    clearInterval(this.taskListTimer)
  },
  methods: {
    test(row){
      this.gridData =[{job_name:111,job_url:'soihdaidhi'}]
      if(row.status!==3){
        this.gridData.map(item=>{
          this.setTimer(item)
        })
      }
      this.dialogTask=true;
      setInterval(()=>{
        this.gridData =[{job_name:111,job_url:'soihdaidhi'}]
        this.isDownload=true;
      },2000)
    },
    closeDialog(){

      this.dialogTask=false;
      clearInterval(this.detailTimer)
    },
    getDataList(page, limit, keywords) {//获取列表
      this.loading=true;
      const that=this;
      if(this.allIsWx3=='true'){
        console.log('三方走这儿')
        getTaskList({page, limit, keywords}).then(res => {
          this.tableData = res.data.list
          this.total = res.data.total
          this.loading = false
        }).catch(err => {
          this.loading = false
        })
        that.taskListTimer=setInterval(()=>{
          getTaskList({page, limit, keywords}).then(res => {
            that.tableData = res.data.list
            let statusFlag=true;
            res.data.list.map(item=>{
              if(item.status!=3){
                statusFlag=false
              }
            })
            that.total = res.data.total
            statusFlag ? clearInterval(that.taskListTimer) : '';
          })
        },10000)
      }else{
        console.log('自建')
        getTaskForZJ({page,limit,keywords}).then(res=>{
          this.tableData = res.data.list
          this.total = res.data.total;
          this.loading = false
        }).catch(err=>{
          this.loading = false
          console.log(err)
        })
        that.taskListTimer=setInterval(()=>{
          getTaskForZJ({page, limit, keywords}).then(res => {
            that.tableData = res.data.list
            let statusFlag=true;
            res.data.list.map(item=>{
              if(item.status!=3){
                statusFlag=false
              }
            })
            that.total = res.data.total
            statusFlag ? clearInterval(that.taskListTimer) : '';
          })
        },10000)
      }


    },
    clearInput() {//清空搜索返回全部
      if (!this.form.search) {
        this.getDataList(1, 10)
      }
    },
    goSearch() {//搜索关键字
      clearInterval(this.taskListTimer);
      if (this.form.search) {
        if (this.form.search.length > 20 || this.form.search.length < 2) {
          this.$message({
            message: '关键字仅限2到20个字以内',
            type: 'warning'
          })
        } else {
          this.getDataList(1, 10, this.form.search)
        }
      } else {
        this.$message({
          message: '请输入搜索关键字',
          type: 'warning'
        })
      }
    },
    handleCurrentChange(page) {
      clearInterval(this.taskListTimer);
      this.getDataList(page, 10, this.form.search ? this.form.search : null)
    },
    handleDownLoad(url){
      console.log('下载了',url);
      window.open(url)
    },
    checkDownload(row){
      clearInterval(this.taskListTimer);
      if(this.allIsWx3=='true'){
        this.handleCheck(row)
      }else{
        this.downloadZj(row)
      }
    },
    downloadZj(row){
      downLoadZj({task_id: row.id}).then(res=>{
        this.isDownload = true;
        this.gridData = res.data;
        this.dialogTask=true;
      })
    },
    handleCheck(row) {//查看详情

      getTaskDetail({task_id: row.id}).then(res => {
        res.data.job_list.map(item => {
          item.job_url=res.data.proxy_download+encodeURIComponent(item.job_url);
        })
        this.gridData = res.data.job_list;
        if(row.status!==3){
          this.gridData.map(item=>{
            this.jobNameList.push(item.job_name)
            this.setTimer(item)
          })
        }
      })
      if(row.status===3) {
        this.isDownload = true;
      }else{
        this.jobNameList = [];
        this.detailTimer = setInterval(() => {
          getTaskDetail({task_id: row.id}).then(res => {

            res.data.job_list.map(item => {
              item.job_url=res.data.proxy_download+encodeURIComponent(item.job_url);
              this.jobNameList.push(item.job_name)
            })
            this.gridData = res.data.job_list;
          }).catch(err => {
            clearInterval(this.detailTimer)
          })
        }, 10000);

      }
      this.dialogTask=true;
    },
    setTimer(item) {
      const that = this;
      this.getExport(item.jobid).then(_=>{
        clearInterval(timer) //清理定时任务
      })
      let timer = setInterval(() => {
        that.getExport(item.jobid).then(_=>{
          clearInterval(timer) //清理定时任务
        })
      }, 60000);
    },
    getExport(jobid){
      const that=this;
      return  getExportId({jobid: jobid}).then(res => {
        if (res.code === 200) {
          that.gridData = [];
          that.jobNameList.map(item=>{
            that.gridData.push({
              job_name: item,
              job_url: res.data.proxy_download+res.data.url
            })
          })
          that.isDownload = true;
        } else {
          console.log(res)
        }
      }).catch(function (error) {

      });
    },
  },
}
</script>

<style lang="less" scoped>
.asynchronous {
  .async-title {
    text-align: center;
    line-height: 48px;
    height: 48px;
    background: #F8F9FA;
    border-bottom: 1px solid #DBDBDB;
  }

  .async-content {
    width: 100%;
    margin: 40px 0;
    .search-form {
      width: 80%;
      margin:0 auto;
      /deep/ .el-form {
        position: relative;

        .search-btn {
          position: absolute;
          right: 12px;
          top: 11px;
          cursor: pointer;
        }
      }
    }

    /deep/ .el-table {
      margin:0 auto 24px;
      //margin-bottom: 24px;

      .task-name {
        margin-bottom: 5px;
      }

      /deep/ thead {
        /deep/ th {
          padding: 8px 0;
          font-weight: 400;
          color: #606266;
        }

        /deep/ tr, th, td {
          background: #DFE9F5 !important;
        }
      }

      .success-status {
        color: #07C160;
      }

      .ing-status {
        color: #0082ef;
      }
      .pass-status{
        color:#ccc;
      }
      .fail-status {
        color: rgb(230, 162, 60);
      }

      .tab-btn {
        color: #004580;
      }
    }
  }

}

/deep/ .el-dialog {
  .download-xlx{
    color: #406080;
    cursor: pointer;
  }
  .el-dialog__header {
    padding: 13px 20px;
    border-bottom: 1px solid #E6E6E6;

    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
    }
  }

  .el-dialog__body {
    padding: 20px 25px;

    /deep/ .el-table {
      margin-bottom: 24px;

      /deep/ thead {
        /deep/ th {
          padding: 8px 0;
          font-weight: 400;
          color: #606266;
        }

        /deep/ tr, th, td {
          background: #DFE9F5 !important;
        }
      }

      .tab-btn {
        color: #004580;
      }
    }
  }
}
</style>